import React from 'react';

import About from '../../components/Home/About';
import Banner from '../../components/Home/Banner';
// import Blog from '../../components/Home/Blog';
// import Courses from '../../components/Home/Courses';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ServicesList from '../../components/Home/ServicesList';
// import Testimonials from '../../components/Home/Testimonials';

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <About />
      {/* <Testimonials /> */}

      <section className="transition-home">
        <div className="container">
          <h2>
            ESTEJA ENTRE OS GRANDES,
            <span>FAÇA PARTE DESTE GRUPO!</span>
          </h2>
          <a
            href="http://irion.kpages.online/abaf_assinatura"
            className="btn-larger btn-yellow"
          >
            seja um agente associado
          </a>
        </div>
      </section>

      <ServicesList />
      {/* <Blog />
      <Courses /> */}
      <Footer />
    </>
  );
}

export default Home;
