import { createGlobalStyle } from 'styled-components';

import banner01Img from '../assets/images/banner01.jpg';
import bgInterna01Img from '../assets/images/bg-interna01.jpg';
import bgInterna02Img from '../assets/images/bg-interna02.jpg';
import bgInterna03Img from '../assets/images/bg-interna03.jpg';
import bgInterna04Img from '../assets/images/bg-interna04.jpg';
import bgTransitionHome from '../assets/images/bg-transition-home.jpg';
import mobileCardImage from '../assets/images/mobile-card.jpg';
import bgCursosImg from '../assets/images/bg-cursos.jpg';
import bgCtaImg from '../assets/images/bg-cta.jpg';
import bgFooterImg from '../assets/images/bg-footer.jpg';

export const ResponsiveStyle = createGlobalStyle`
/*	12 COLUMN : RESPONSIVE GRID SYSTEM
	DEVELOPER : DENIS LEBLANC
	URL : http://responsive.gs
	VERSION : 3.0
	LICENSE : GPL & MIT */


/* 	SET ALL ELEMENTS TO BOX-SIZING : BORDER-BOX */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*	If you need support for IE7 and lower make
		sure the boxsizing.htc file is linked properly.
		More info here:  https://github.com/Schepp/box-sizing-polyfill */
}


/*	MAIN CONTAINER
	Set the width to whatever you want the width of your site to be. */

.container-fluid {
    margin: 0 20px;
}

.container {
    max-width: 1196px;
    margin: 0 auto;
}


/*	SELF CLEARING FLOATS - CLEARFIX METHOD */

.container:after,
.row:after,
.col:after,
.clr:after,
.group:after {
    content: "";
    display: table;
    clear: both;
}


/* 	DEFAULT ROW STYLES
	Set bottom padding according to preference */

.row {
    padding-bottom: 0em;
}


/* DEFAULT COLUMN STYLES */

.col {
    display: block;
    float: left;
    width: 100%;
}

@media ( min-width: 768px) {
    .gutters .col {
        margin-left: 2%;
    }
    .gutters .col:first-child {
        margin-left: 0;
    }
}


/*	COLUMN WIDTH ON DISPLAYS +768px
	You might need to play with media queries here to suite your design. */

@media ( min-width: 768px) {
    .grid_1 {
        width: 8.33333333333%;
    }
    .grid_2 {
        width: 16.6666666667%;
    }
    .grid_3 {
        width: 25%;
    }
    .grid_4 {
        width: 33.3333333333%;
    }
    .grid_5 {
        width: 41.6666666667%;
    }
    .grid_6 {
        width: 50%;
    }
    .grid_7 {
        width: 58.3333333333%;
    }
    .grid_8 {
        width: 66.6666666667%;
    }
    .grid_9 {
        width: 75%;
    }
    .grid_10 {
        width: 83.3333333333%;
    }
    .grid_11 {
        width: 91.6666666667%;
    }
    .grid_12 {
        width: 100%;
    }
    .gutters .grid_1 {
        width: 6.5%;
    }
    .gutters .grid_2 {
        width: 15.0%;
    }
    .gutters .grid_3 {
        width: 23.5%;
    }
    .gutters .grid_4 {
        width: 32.0%;
    }
    .gutters .grid_5 {
        width: 40.5%;
    }
    .gutters .grid_6 {
        width: 49.0%;
    }
    .gutters .grid_7 {
        width: 57.5%;
    }
    .gutters .grid_8 {
        width: 66.0%;
    }
    .gutters .grid_9 {
        width: 74.5%;
    }
    .gutters .grid_10 {
        width: 83.0%;
    }
    .gutters .grid_11 {
        width: 91.5%;
    }
    .gutters .grid_12 {
        width: 100%;
    }
}

@media ( max-width: 1002px) {
    .container {margin-left: 15px; margin-right: 15px;}
}
`;

export const SlickStyle = createGlobalStyle`
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
`;

export const SlickThemeStyle = createGlobalStyle`
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 45%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before {
  font-family: 'slick';
    font-size: 50px;
    line-height: 1;
    text-indent: 0;

    opacity: .75;
    color: #003652;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-next:before
{
    font-family: 'slick';
    font-size: 50px;
    line-height: 1;
    text-indent: 0;

    opacity: .75;
    color: #003652;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -56px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
  content: "❯";
  text-indent: 0;
  transform: rotate(180deg);
}
[dir='rtl'] .slick-prev:before
{
  content: "❯";
  text-indent: 0;
  transform: rotate(180deg);
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
  content: "❯";
  text-indent: 8px;
}
[dir='rtl'] .slick-next:before
{
  content: "❯";
  text-indent: 8px;
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
`;

export const GlobalStyle = createGlobalStyle`
* {margin: 0; padding: 0; font-family: 'Source Sans Pro';
-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
body, html {height: 100%;}
body {background: #fff;}

#root {margin: 0; padding: 0}

/* BOTÕES */
.btn-small {display: inline-block; min-height: 50px; border:0; line-height: 50px; padding-left: 50px; padding-right: 50px; text-transform: uppercase; font-size: 18px; font-weight: 600; text-decoration: none;}
.btn-medium {display: inline-block; min-height: 60px; border:0; line-height: 60px; padding-left: 50px; padding-right: 50px; text-transform: uppercase; font-size: 22px; font-weight: 600; text-decoration: none;}
.btn-larger {display: inline-block; min-height: 70px; border:0; line-height: 70px; padding-left: 50px; padding-right: 50px; text-transform: uppercase; font-size: 22px; font-weight: 600; text-decoration: none;}
.btn-blue {background-color: #003766; color: #fff;}
.btn-yellow {background-color: #FFC400; color: #002048;}
/* FIM BOTÕES */

/* HR */

hr {display: inline-block; overflow: hidden; border: 0; height: 6px; border-radius:10px; min-width: 10%; margin: 40px 0; }
.hr-yellow {background-color: #FFC400;}
.hr-blue {background-color: #367EBC;}
/* FIM HR */

/* HEADER */
header {border-top: 6px solid #FFC400; height: 80px;}
header .logo {display: inline-block; margin-top: 9px;}
header .logo svg {height: 60px; width: auto;}
header nav {line-height: 70px; text-align: right; display: inline-block; margin-right: 30px;}
header nav a {display: inline-block; font-size: 18px; color: #002854; text-decoration: none; margin-left: 30px; line-height: 20px; position: relative;}
header nav a:hover {color: #FFC400;}
header nav a::after {content:"."; display: inline-block; position: absolute; height: 6px; background-color: #FFC400; color: #FFC400; bottom: -29px; line-height: 6px; width:100%; left:0; overflow: hidden; border-radius: 10px; opacity: 0; visibility: 0; z-index: 9999;}
header nav a:hover::after {opacity: 1; visibility: visible;  -webkit-transition: opacity 0.5s ease-in-out; -moz-transition: opacity 0.5s ease-in-out; -ms-transition: opacity 0.5s ease-in-out;
 -o-transition: opacity 0.5s ease-in-out; transition: opacity 0.5s ease-in-out;}
header .bt-login {border: 1px solid #002854; font-size:15px; border-radius: 3px; padding: 8px 12px 3px 12px; display: inline-block; text-decoration: none; color: #002854;}
header .bt-login:hover::after {opacity: 0; visibility: hidden;}
header .bt-login:hover {border: 1px solid #FFC400; color: #002854; background-color: #FFC400;}
header .bt-login i {display: inline-block; vertical-align: middle; line-height: 20px; margin-right: 7px;}
#menu__toggle {display: none;}
.menu-line {text-align: right;}
/* HEADER */

/* BANNER HOME E BANNER INTERNAS */
.banner {min-height: 400px;}
.banner .slick-next {width: 1196px; display: inline-block; margin: 0 auto; left: 0; text-align: right; pointer-events: none;}
.banner .slick-prev {width: 1196px; display: inline-block; margin: 0 auto; right: 0; text-align: left; z-index: 999; pointer-events: none; left:0;}
.banner .slick-prev, .banner .slick-next {top: 80%;}
.banner .slick-prev:before, .banner .slick-next:before {pointer-events: auto; background-color: #fff; width: 60px; height: 60px; text-align: center !important; border-radius: 60px; display: inline-block; font-size: 40px; margin: 0 auto; -moz-box-shadow: 0 0 20px #fff;-webkit-box-shadow: 0 0 20px #fff;
box-shadow: 0px 0px 20px #fff}
.banner .slick-next:before {line-height: 55px;}
.banner .slick-prev:before {line-height: 50px;}
.banner .slick-dots {bottom: 55px; z-index: 9999; width: 1196px; margin: 0 auto; position: relative; text-align: center;}
.banner .slick-dots li button:before {width: 10px; height: 10px; border: 2px solid #fff; border-radius: 12px; font-size: 0; opacity: 1;}
.banner .slick-dots .slick-active button:before {background-color: #fff;}
.banner .card-banner {min-height: 400px; background-repeat: no-repeat; background-size: cover; background-position: center center;}
.banner .banner01 {background-image: url(${banner01Img});}
.banner .card-banner h1 { font-size: 35px; font-weight: 400; color: #fff; margin-top: 20px; line-height: 50px;}
.banner .card-banner h1 span {font-size: 60px; font-weight: 600; display: block;}
.banner .card-banner h2 { font-size: 30px; font-weight: 400; color: #fff; margin-top: 20px; line-height: 40px;}
.banner .card-banner .bt-banner {margin-top: 40px;}

.banner-interna {height: 150px; background-size: cover; background-repeat: no-repeat; line-height: 140px;}
.banner-interna h1 {font-size: 40px; font-weight: 600; color: #fff; overflow: hidden;}
.banner-interna h1 span {float: right; font-size: 18px; color: #FFC400; text-transform: uppercase; font-weight: 400;}
.banner-interna h1 span a {text-decoration: none; color: #fff;}
.bg01 {background-image: url(${bgInterna01Img});}
.bg02 {background-image: url(${bgInterna02Img});}
.bg03 {background-image: url(${bgInterna03Img});}
.bg04 {background-image: url(${bgInterna04Img});}
/* HOME */

.about {padding: 30px 0;}
.about h3 { font-size: 40px; color: #526874; font-weight: 600; line-height: 40px; margin-bottom: 20px;}
.about h4 { font-size: 25px; color: #526874; font-weight: 400; line-height: 28px; margin-bottom: 40px;}
.about hr {width: 20%;}
.about p {color: #526874; font-size: 18px; line-height: 26px; margin-bottom: 15px;}
.about .banner-about {float: right; -webkit-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
-moz-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32); height: 465px; border-radius: 25px; }
.about .banner-about .card {overflow: hidden; height: 465px; border-radius: 25px; }
.about .banner-about .card img {height: 100%;  width: auto;}
.about .banner-about .slick-dots {bottom: -20px; z-index: 9999;  margin: 0 auto; position: relative;}
.about .banner-about .slick-dots li button:before {width: 10px; height: 10px; border: 2px solid #005B88; border-radius: 12px; font-size: 0; opacity: 1;}
.about .banner-about  .slick-dots .slick-active button:before {background-color: #005B88;}

.depoimentos {margin-top: 50px; padding: 60px 0; background-color: #F5F5F5; text-align: center;}
.depoimentos .container {position: relative;}
.depoimentos h2 {text-align: center; font-size: 40px; color: #526874; font-weight: 600;}
.depoimentos hr {width: 20%; margin: 20px auto;}
.depoimentos .banner-depoimentos {margin-top: 10px;}
.depoimentos h5 { font-size: 18px; color: #003666; font-weight: 500; width: 50%; margin: 0 auto;}
.depoimentos .foto { width: 122px; height: 122px; border-radius:122px; margin: 30px auto 20px auto; overflow: hidden; position: relative;}
.depoimentos .foto img {position: absolute; left:-45%;}
.depoimentos h6 {font-family: 'Source Serif Pro', serif; font-weight: 400; font-size: 24px; color: #526874;}
.depoimentos h6 span {font-size: 12px; color: #909090; display: block; text-transform: uppercase; font-weight: 600;}
.depoimentos .slick-prev, .depoimentos .slick-next {top:15%;}
.depoimentos .slick-prev:before, .depoimentos .slick-next:before {pointer-events: auto; background-color: #fff; width: 60px; height: 60px; text-align: center !important; border-radius: 60px; display: inline-block; font-size: 40px; line-height: 57px; margin: 0 auto; -moz-box-shadow: 0 5px 20px rgba(0,0,0,0.32) ; -webkit-box-shadow: 0 5px 20px  rgba(0,0,0,0.32); box-shadow: 0px 5px 20px rgba(0,0,0,0.32);}
.depoimentos .slick-prev:before {line-height: 51px}
.depoimentos .slick-prev {left: 0; z-index: 9999;}
.depoimentos .slick-next {right: 43px;}
.depoimentos .slick-dots li button:hover:before {color: #fff !important; background-color: #7D2021;}
.depoimentos .slick-dots {bottom: -35px; z-index: 9999;  margin: 0 auto; position: relative;}
.depoimentos .slick-dots li button:before {width: 9px; height: 9px; border: 2px solid #3E93B8; border-radius: 12px; font-size: 0; opacity: 1;}
.depoimentos  .slick-dots .slick-active button:before {background-color: #3E93B8;}

.transition-home {min-height: 310px; background-image: url(${bgTransitionHome}); background-repeat: no-repeat; background-size: cover; background-attachment: fixed; background-position: center bottom; text-align: center;}
.transition-home h2 {padding-top: 50px; text-transform: uppercase; font-size: 25px; font-weight: 600; color: #fff; line-height: 45px;}
.transition-home h2 span { font-size: 45px; display: block; font-weight: 600;}
.transition-home hr {display: block; width: 20%; margin: 30px auto 60px auto;}
.transition-home a {margin-top: 30px;}

.list {background-color: #F5F5F5; padding-top: 50px;}
.list .container {background-image: url(${mobileCardImage}); background-repeat: no-repeat; background-position: 100% bottom; }
.list h3 {font-size: 40px; color: #003666; text-transform: uppercase; font-weight: 600; line-height: 42px;}
.list h4 {font-size: 25px; color: #003666; font-weight: 400; margin-top:10px;}
.list hr {width: 20%;}
.list ul {list-style: none;}
.list ul li {overflow: hidden; display: table; margin-bottom: 30px;}
.list ul li span {display: table; height: 75px; float: left;}
.list ul li i { width: 75px; height: 75px; border: 2px solid #005B88; border-radius: 100px;  text-align: center; display: table-cell; vertical-align: middle;}
.list ul li i svg { vertical-align: middle;}
.list ul li h5 {padding-left: 20px; font-size: 25px; color: #005B88; font-weight: 600;}
.list ul li p {padding-left: 20px; font-size: 16px; color: #526874; padding-right: 40px;}
.list a {margin-bottom: 40px; margin-top: 30px;}
.list ul li div {width: 83%; float: left;}
.list .mobile-card {display: none; width: 100%; height: auto;}


.blog-home {padding: 50px 0; text-align: center;}
.blog-home h2 {font-size: 40px; color: #526874; text-align: center; padding-bottom: 50px; font-weight: 600;}

.blog-home .post {padding: 20px 10px;}
.blog-home .post a {text-decoration: none; text-align: left;}
.blog-home .image {height: 275px; overflow: hidden; border-radius: 5px; -moz-box-shadow: 0 5px 20px rgba(0,0,0,0.32) ; -webkit-box-shadow: 0 5px 20px  rgba(0,0,0,0.32); box-shadow: 0px 5px 20px rgba(0,0,0,0.32);}
.blog-home .image img {width: 100%; height: auto;}
.blog-home h4 { font-size: 24px; color: #526874; line-height: 27px; padding: 25px 0; text-decoration: none;}
.blog-home p {font-size: 18px; color: #526874; text-decoration: none;}
.blog-home .btn-blue {margin: 50px 0 0 0;}


.cursos {padding: 60px 0; background-image: url(${bgCursosImg}); background-repeat: no-repeat; background-size: cover;}
.cursos h2 {text-align: center; font-size: 40px; color: #003666; font-weight: 600; text-transform: uppercase;}
.cursos h3 {text-align: center; font-size: 25px; color: #003666; font-weight: 400;}
.cursos hr { display: block; width: 20%; margin: 30px auto;}
.cursos .post-curso {margin: 0 auto; padding-top: 40px;}
.cursos .post-curso .col {padding: 0 20px;}
.cursos .post-curso a {display:block; text-decoration: none;}
.cursos .post-curso .image {overflow: hidden; border-radius:10px; height: 255px; -moz-box-shadow: 0 5px 20px rgba(0,0,0,0.32) ; -webkit-box-shadow: 0 5px 20px  rgba(0,0,0,0.32); box-shadow: 0px 5px 20px rgba(0,0,0,0.32);}
.cursos .post-curso img {width: 100%; height: auto;}
.cursos .post-curso h4 {text-align: center; font-size: 14px; color: #003666; font-weight: 600; padding-top: 20px;}
/* FIM HOME */


/* ABAF */
.abaf {padding-top: 70px;}
.abaf .text {padding-right: 40px;}
.abaf h2 {font-size: 40px; color: #526874; font-weight: 600; line-height: 40px; margin-bottom: 20px;}
.abaf h3 {font-size: 25px; color: #526874; font-weight: 400;}
.abaf p { font-size: 18px; color: #526874; margin-bottom: 25px;}
.abaf .image {margin-bottom: 60px; height: 365px; border-radius: 25px;  -webkit-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
-moz-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32); overflow: hidden;}
.abaf .image img {width: 100%; height: auto;}
.abaf .banner-about { display: none; -webkit-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
-moz-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32); height: 465px; border-radius: 25px; }
.abaf .banner-about .card {overflow: hidden; height: 465px; border-radius: 25px; }
.abaf .banner-about .card img {height: auto;  width: 100%;}
.abaf .banner-about .slick-dots {bottom: -20px; z-index: 9999;  margin: 0 auto; position: relative;}
.abaf .banner-about .slick-dots li button:before {width: 10px; height: 10px; border: 2px solid #005B88; border-radius: 12px; font-size: 0; opacity: 1;}
.abaf .banner-about  .slick-dots .slick-active button:before {background-color: #005B88;}
.abaf .diretoria {background-color: #F5F5F5; margin-top: 50px; padding: 60px 0; text-align: center;}
.abaf .diretoria h2 {font-size: 40px; color: #526874; font-weight: 600; text-transform: uppercase; margin-bottom: 0;}
.abaf .diretoria hr {width: 20%;}
.abaf .diretoria .foto { width: 148px; height: 148px; border-radius:122px; margin: 30px auto 20px auto; overflow: hidden; position: relative;}
.abaf .diretoria .foto img {left:0; width: auto; height: 100%; display: inline-block;}
.abaf .diretoria h6 {font-family: 'Source Serif Pro', serif; font-weight: 400; font-size: 24px; color: #526874;}
.abaf .diretoria h6 span {font-size: 12px; color: #909090; display: block; text-transform: uppercase; font-weight: 600;}
.abaf .diretoria .slick-dots li button:hover:before {color: #fff !important; background-color: #7D2021;}
.abaf .diretoria .slick-dots {bottom: -35px; z-index: 9999;  margin: 0 auto; position: relative;}
.abaf .diretoria .slick-dots li button:before {width: 9px; height: 9px; border: 2px solid #3E93B8; border-radius: 12px; font-size: 0; opacity: 1;}
.abaf .diretoria  .slick-dots .slick-active button:before {background-color: #3E93B8;}
/* FIM ABAF */

/* DÚVIDAS */
.duvidas {padding: 60px 0; text-align: center;}
.duvidas h3 {font-weight: 400; font-size: 25px; color: #526874; margin-bottom: 15px;}
.duvidas h4 {font-weight: 600; font-size: 25px; color: #526874; margin-bottom: 30px;}
.duvidas .faq {margin: 0 auto; text-align: left;}
.duvidas .faq ul {list-style: none;}
.duvidas .faq ul li {margin-bottom: 20px;}
.duvidas .faq ul li label {display: block; padding: 20px; border: 1px solid #C0DBE8; border-radius: 4px;}
.duvidas .faq ul li label h6 {font-size: 16px; color: #526874; font-weight: 600; position: relative;}
.duvidas .faq ul li label .question p { font-size: 15px; color: #526874; margin-top: 20px;}
.duvidas .faq ul li label input {display: none;}
.duvidas .faq ul li label .question {max-height: 0; overflow: hidden; -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;  }
.duvidas .faq ul li label input:checked ~ .question {max-height: 800px; -webkit-transition: max-height 2s;
  -moz-transition: max-height 2s;
  -ms-transition: max-height 2s;
  -o-transition: max-height 2s;
  transition: max-height 2s;  }
.duvidas .faq ul li label h6::after {content: "❯"; cursor: pointer;
transform: rotate(90deg); height: 30px; width: 30px; text-align: center; display:block; position: absolute; right: 0; top: 0; color: #16539B; font-weight: 700; border-radius: 26px; -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05);
-moz-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05);
box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05); line-height: 26px;  transform: rotate(90deg); }
.duvidas .faq ul li label input:checked ~ h6::after {transform: rotate(270deg);}
.duvidas .contact-duvidas {text-align: center; padding-top: 50px; padding-bottom: 40px;}
.duvidas .contact-duvidas h5 {font-size: 30px; color: #526874; font-weight: 600; margin-bottom: 0;}
.duvidas .contact-duvidas a { color: #005B88; font-size: 18px; text-decoration: none;}
.duvidas .contact-duvidas a::before {content: "❯"; font-size: 14px; margin-right: 5px;}
.duvidas .contact-duvidas a:hover {text-decoration: underline;}
/* FIM DÚVIDAS */

/* AGENTES */
.agentes {padding: 50px 0; text-align: center;}
.agentes .search form {margin: 0 auto; overflow: hidden;}
.agentes .search form input {height: 50px; border: 1px solid #005B88; border-radius:4px 0 0 4px; border-right:0; background-color: #fff; width: 85%; padding-left: 20px; float: left;  font-size: 18px;}
.agentes .search form button {height: 50px; border: 1px solid #005B88; border-radius:0 4px 4px 0; border-left:0; background-color: #fff; width: 15%; padding-left: 20px; text-align: center; cursor: pointer;}
.agentes .search form button svg { height: 30px; margin-top: 5px; }
.agentes .search form button:hover svg {opacity: 0.5;}
.agentes hr {width: 20%;}
.agentes .pagination {margin-bottom: 20px; margin-top: 20px; font-size: 18px; color: #526874;}
.agentes .pagination a {color: #526874; text-decoration: none; margin: 0 10px;}
.agentes .pagination .active {color: #FFC400; pointer-events: none;}
.agentes .pagination .next {margin: 0 0 0 10px; line-height: 20px;}
.agentes .pagination .prev {margin: 0 10px 0 0; line-height: 20px;}
.agentes .pagination .next::after {content:"〉"; color: #16539B;}
.agentes .pagination .prev::before {content:"〈"; color: #16539B;}
.agentes .agent-list li {margin-bottom: 20px;}
.agentes .agent-list li:nth-child(odd) {margin-left: 0;}
.agentes .agent-list li a {display: block; padding: 20px; border: 1px solid #C0DBE8; border-radius: 4px; text-decoration: none;}
.agentes .agent-list li .image i {display: block; width: 85px; height: 85px; position: relative; overflow: hidden; border-radius: 85px; border: 1px solid #005B88;}
.agentes .agent-list li .image i img {width: 100%; height: auto;}
.agentes .agent-list li .text {text-align: left; padding-left: 10px;}
.agentes .agent-list li .text h5 { font-size: 18px; color: #00386E; font-weight: 600;}
.agentes .agent-list li p {font-size: 14px; color: #526874;}
.agentes .agent-list li .action {text-align: right;}
.agentes .agent-list li .action .name {font-size: 14px; color: #526874;}
.agentes .agent-list li .action .name i {padding-right: 5px;}
.agentes .agent-list li .action .button {display: inline-block; width: 45px; height: 45px; border-radius: 45px; line-height: 45px; text-align: center; -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05);
-moz-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05);
box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.05); margin-top: 15px;}
/* FIM AGENTES */

/* DETALHE AGENTES */
.agente-detalhe {padding: 30px 0 0 0;}
.agente-detalhe .back {text-align: right; padding-bottom: 30px;}
.agente-detalhe .back a { font-size: 20px; color: #005B88; text-decoration: none;}
.agente-detalhe .back a::before {content:"〈"; margin-right: 10px; }
.agente-detalhe .brand {overflow: hidden;}
.agente-detalhe .brand .image {display: block; width: 160px; height: 160px; border: 1px solid #005B88; border-radius: 160px; overflow: hidden; float: left;}
.agente-detalhe .brand .image img {width: 100%; height: 100%;}
.agente-detalhe .brand .text {padding-left: 25px; width: 75%; float: left; padding-right: 40px;}
.agente-detalhe .brand .text h5 {font-size: 30px; color: #00386E; font-weight: 600; line-height: 35px; margin-bottom: 10px;}
.agente-detalhe .brand .text p { font-size: 16px; color: #526874; margin-bottom: 10px;}
.agente-detalhe .brand .text p i {vertical-align: middle; text-align: center; display: inline-block; width: 10%; margin-left: -15px;}
.agente-detalhe .brand .text p a {text-decoration: none; color: #526874; display: inline-block; width: 90%; border-top: 1px solid rgba(0,91,136,0.2); padding:10px 0 0 0;}
.agente-detalhe .logos {text-align: center;}
.agente-detalhe .logos p {display: inline-block; text-align: center; font-size: 16px; color: #062D58; font-weight: 600; vertical-align: top; padding: 0 20px;}
.agente-detalhe .logos p i {display: block; padding-top: 15px;}
.agente-detalhe .logos p:first-child i {padding-top: 25px;}
.agente-detalhe .about {padding-top: 0; margin-left: 0;}
.agente-detalhe .about p {font-size: 18px; color: #526874;}
.agente-detalhe .banner-about {float: right; -webkit-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
-moz-box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32);
box-shadow: 0px 0px 30px 3px rgba(0,0,0,0.32); height: 465px; border-radius: 25px; }
.agente-detalhe .banner-about .card {overflow: hidden; height: 465px; border-radius: 25px; }
.agente-detalhe .banner-about .card img {height: 100%;  width: auto;}
.agente-detalhe .banner-about .slick-dots {bottom: -20px; z-index: 9999;  margin: 0 auto; position: relative;}
.agente-detalhe .banner-about .slick-dots li button:before {width: 10px; height: 10px; border: 2px solid #005B88; border-radius: 12px; font-size: 0; opacity: 1;}
.agente-detalhe .banner-about  .slick-dots .slick-active button:before {background-color: #005B88;}
.agente-detalhe .sobre {padding-top: 50px;}
.agente-detalhe .address {padding-top: 50px;}
.agente-detalhe .address h5 {font-size: 25px; color: #062D58; font-weight: 600; padding-bottom: 15px; border-bottom: 1px solid rgba(0,91,136,0.2); margin-bottom: 40px;}
.agente-detalhe .address p {font-size: 20px; color: #526874; font-weight: 400;}
.agente-detalhe .address p i {margin-right: 15px;}
.agente-detalhe .address .name {padding-left: 30px; font-weight: 600;}
.agente-detalhe .address p a {color: #526874; text-decoration: none;}
.agente-detalhe iframe {width: 100%; height: 350px; margin-top: 60px; display: block; border: 0;}
/* FIM DETALHE AGENTES */

/* CONTATO */
.contato {padding: 70px 0; text-align: center;}
.contato h2 {font-size: 40px; font-weight: 600; color: #526874; margin-bottom: 40px;}
.contato hr {width: 20%; margin-top: 30px; margin-bottom: 60px;}
.contato form {margin: 0 auto;}
.contato input {height: 60px; border: 1px solid #C0DBE8; border-radius: 4px; padding-left: 20px; font-size: 16px; width: 100%; margin-bottom: 20px;}
.contato input.error {border-color: rgb(206, 28, 34)}
.contato textarea {height: 200px; border: 1px solid #C0DBE8; border-radius: 4px; padding-left: 20px; padding-top: 20px; font-size: 16px; width: 100%; margin-bottom: 20px;}
.contato textarea.error {border-color: rgb(206, 28, 34)}
.contato span.error {display: block; color: rgb(206, 28, 34); text-align: left; margin-bottom: 20px; }
.contato button {float: right; cursor: pointer; border: 0; background-color: #005B88; color: #fff; width: 160px; height: 70px; font-size: 22px; font-weight: 600;}
/* FIM CONTATO */

/* CTA */
.cta {background-image: url(${bgCtaImg}); background-repeat: no-repeat; height: 265px;}
.cta h3 { font-size: 35px; color: #00386E; font-weight: 600; margin-top: 80px;}
.cta h4 { font-size: 45px; color: #00386E; font-weight: 600;}
.cta .btn-blue {color: #FFC400; margin-top: 105px; }
.cta .row .col:last-child {text-align: right;}
/* FIM CTA */

/* FOOTER */
footer {background-image:url(${bgFooterImg}); background-repeat: no-repeat; background-size: cover;  padding-top: 120px; text-align: center; padding-bottom: 80px; background-attachment: fixed;}
footer .flags img {margin: 0 10px;}
footer .columns {margin-top: 60px;}
footer .columns .col {border-left: 1px solid rgba(255,255,255,0.4); text-align: left; min-height: 230px;}
footer .columns .col:first-child {border: 0;}
footer .columns .logo-footer svg {width: 85%; height: auto;}
footer .columns ul {list-style: none; padding-left: 30px;}
footer .columns ul li {font-size: 18px; padding: 10px 0;}
footer .columns ul li a {text-decoration: none; color: #fff;}
footer .columns ul li a i {display: inline-block; vertical-align:middle; margin-right: 10px;}
footer .columns p {font-size: 18px; color: #fff; overflow: hidden; padding-left: 30px; margin-bottom: 30px;}
footer .columns p i {display: inline-block; float: left; width: 5%; text-align: center;}
footer .columns p span {display: inline-block; float: left; width: 88%; padding-left: 15px;}
footer .columns p a {color: #fff; text-decoration: none;}
footer .columns .social {text-align: left; padding-top: 20px; padding-left: 65px}
footer .columns .social a {margin: 0 15px 0 0; text-decoration: none;}
footer .copyright {margin-top: 90px; color:#fff; font-size: 15px;}
footer .copyright span {display: block; font-size: 12px; text-transform: uppercase; opacity: 0.5; border-top: 1px solid rgba(255,255,255,0.5); margin-top: 25px; padding-top: 25px;}
/* FIM FOOTER */

/*_____________________ RESPONSIVO _____________________*/
@media (max-width: 768px) {

body {padding-top: 80px;}

/* HEADER */
header {border-top:4px solid #FFC400; height: 80px; width: 100%; position: fixed; z-index: 999999; background-color: #fff; top: 0;}
header .logo svg { width: 118px; height: auto;}
header nav {display: none;}
header nav a:hover::after {visibility: hidden;}
#menu__toggle {opacity: 0; display: none;}
#menu__toggle:checked ~ .menu__btn > span { transform: rotate(45deg);}
#menu__toggle:checked ~ .menu__btn > span::before {top: 0;
transform: rotate(0);}
#menu__toggle:checked ~ .menu__btn > span::after {
top: 0;transform: rotate(90deg);}
#menu__toggle:checked ~ .menu {visibility: visible;left: 0;}
.menu__btn {display: flex;align-items: center;position: absolute;right: 70px;top: 28px;width: 26px;height: 26px;cursor: pointer;z-index: 1;}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {display: block;position: absolute;width: 100%;height: 3px;background-color: #00386E;transition-duration: .25s;}
.menu__btn > span::before {content: '';top: -8px;}
.menu__btn > span::after {content: '';top: 8px;}
.menu {display: block;position: absolute;visibility: hidden;top: 80px;left: -100%;width: 100%;height: auto;margin: 0;list-style: none;background-color: #fff;transition-duration: .25s; padding: 0 20px 20px 20px; z-index: 999999; }
header .bt-login {position: absolute; top: 28px; right: 20px; border: 0; padding: 0; font-size: 0;}
header .bt-login svg {width: 26px; height: 26px;}
.menu a {display: block; text-align: center; margin-left: 0; font-size: 16px; margin-top: 15px;  border-bottom: 1px solid #EFEFEF; padding-bottom: 15px;}
/* FIM HEADER */

/* BANNER HOME E BANNER INTERNAS */
  .banner {width: 100%; overflow: hidden; min-height: 260px;}
  .banner .card-banner {min-height: 260px; background-position: left center;}
  .banner .card-banner h1 {margin-top: 30px; font-size: 18px; line-height: 30px;}
  .banner .card-banner h1 span { font-size: 30px;}
  .banner .card-banner h2 {font-size: 15px; line-height: 22px; margin-top: 15px;}
  .banner .card-banner .bt-banner {min-height: 42px; font-size: 18px; width: 154px; line-height: 42px; padding-left: 0; padding-right: 0; text-align: center; margin-top: 20px;}
  .banner .slick-prev, .banner .slick-next {display: none !important;}
  .banner .slick-dots {text-align: center; width: 100%; bottom: 35px;}
  .banner-interna {min-height: 100px; line-height: 100px;}
  .banner-interna h1 {font-size: 18px;}
  .banner-interna h1 span {font-size: 12px; width: 170px; line-height: 20px; padding-top: 40px; text-align: right;}
  /* FIM BANNER HOME E BANNER INTERNAS */

  /* HOME */
  .about {padding-top: 0; text-align: center; padding-bottom: 0;}
  .about .grid_5 {float: right;}
  .about .banner-about {float: left;}
  .about .banner-about .card {height: 229px;}
  .about .banner-about {height: 229px;}
  .about  h3 {margin-top: 40px; font-size: 25px; line-height: 28px; margin-bottom: 20px; text-align: left;}
  .about h4 {font-size: 18px; line-height: 23px; text-align: left;}
  .about hr {width: 40%; margin: 30px 0 20px 0;}
  .about p {font-size: 15px; text-align: left;}
  .about a {float: left;}
  .depoimentos {padding-top: 40px;}
  .depoimentos h2 {font-size: 25px;}
  .depoimentos hr {width: 35%;}
  .depoimentos .banner-depoimentos {margin-top: 20px;}
  .depoimentos .slick-prev:before, .depoimentos .slick-next:before {display: none;}
  .depoimentos h5 {width: 100%; font-size: 15px;}
  .transition-home {min-height: 1px; padding-bottom: 60px; background-attachment: inherit;}
  .transition-home h2 {font-size: 18px; line-height: 30px; padding-top: 60px;}
  .transition-home hr {width: 35%; margin: 20px auto 40px auto;}
  .transition-home h2 span {font-size: 25px;}
  .transition-home .btn-larger {padding: 0; width: 100%; min-height: 50px; line-height: 50px; font-size: 18px;}
  .list {text-align: center;}
  .list .container {background: none;}
  .list h3 {font-size: 25px; line-height: 28px;}
  .list h4 {font-size: 18px;}
  .list hr {display: block; margin: 30px auto 40px auto;}
  .list ul li {padding-bottom: 28px;}
  .list ul li:last-child {border: 0;}
  .list ul li span {display: initial; height: 75px;float: none;}
  .list ul li h5 {padding-left: 0; text-align: center; padding-top: 10px;}
  .list ul li div {width: 100%; float: none;}
  .list a {margin-top: 0; font-size: 18px; height: 42px;}
  .list .mobile-card {display: block; margin-left: -30px; width: 105%;}
  .blog-home {padding-bottom: 40px;}
  .blog-home h2 {font-size: 25px; line-height: 30px;}
  .blog-home .post {padding: 20px 15px 0 0;}
  .blog-home .image {height: 215px;}
  .blog-home h4 {font-size: 18px; line-height: 22px; padding-bottom: 15px;}
  .blog-home .btn-blue {font-size: 18px; height: auto; line-height: 22px; padding: 22px 40px;}
  .blog-home p {font-size: 15px; line-height: 18px;}
  .cursos {padding: 40px 0 20px 0;}
  .cursos h2 {font-size: 25px; text-align: left;}
  .cursos h3 {font-size: 18px; text-align: left; margin-top: 10px;}
  .cursos .post-curso {padding-top: 20px;}
  .cursos .post-curso a {margin-bottom: 40px;}
  .cursos .post-curso h4 {font-size: 18px; line-height: 22px;}
  .cursos .post-curso .image {height: 205px;}
  /* FIM HOME */

  /* ABAF */
  .abaf {padding-top: 40px;}
  .abaf .text {padding-right: 0;}
  .abaf h2 {font-size: 25px; line-height: 30px;}
  .abaf h3 {font-size: 18px;}
  .abaf hr {width: 40%; display: block; margin: 25px auto;}
  .abaf .image {display: none;}
  .abaf .banner-about {display: block; margin-bottom:80px;}
  .abaf .banner-about {height: 245px;}
  .abaf .banner-about .card {height: 255px;}
  .abaf .diretoria h2 {font-size: 25px;}
    /* FIM ABAF */

    /* DÚVIDAS */
  .duvidas {padding: 40px 0;}
  .duvidas h3 {font-size: 18px; text-align: left;}
  .duvidas h4 {text-align: left; font-size: 22px;}
  .duvidas h4 br {display: block; margin-bottom: 20px;}
  .duvidas hr {width: 30%;}
  .duvidas .faq ul li label {padding-right: 35px;}
  .duvidas .faq ul li label h6 {padding-right: 20px;}
  .duvidas .faq ul li label h6::after {margin-right: -27px;}
    /* FIM DÚVIDAS */

    /* AGENTES */
  .agentes {padding: 40px 0;}
  .agentes .search form input {height: 60px; font-size: 15px;}
  .agentes .search form button {height: 60px; padding-left: 0;}
  .agentes .search form button svg {width: 25px; height: auto;}
  .agentes .image {width: auto;}
  .agentes .agent-list li .image i {width: 75px; height: 75px;}
  .agentes .agent-list li .text {width: 220px;}
  .agentes .agent-list li a {padding: 15px;}
  .agentes .agent-list li .text h5 {font-size: 16px;}
  .agentes .agent-list li p {font-size: 12px;}
  .agentes .agent-list li .action {text-align: left;}
  .agentes .agent-list li .action .name {width: 60%; margin-top: 25px; float: left;}
  .agentes .agent-list li .action .button {float: right; margin-top: 10px;;}
    /* FIM AGENTES */

    /* DETALHE AGENTES */
  .agente-detalhe { padding: 40px 0 0 0;}
  .agente-detalhe .back {display: none;}
  .agente-detalhe .brand  {text-align: center;}
  .agente-detalhe .brand .image {width: 125px; height: 125px; margin: 0 auto; float: none;}
  .agente-detalhe .brand .text {width: 100%; padding-right: 0; padding-left: 0;}
  .agente-detalhe .brand .text h5 {font-size: 25px; line-height: 30px; padding-top: 10px; padding-bottom: 10px;}
  .agente-detalhe .brand .text p {text-align: left;}
  .agente-detalhe .brand .text p i {margin-left: -5px;}
  .agente-detalhe .logos p {margin: 20px 0 10px 0; border: 1px solid #C0DBE8; border-radius: 4px; width: 100%; padding: 20px;}
  .agente-detalhe .banner-about {height: 220px; margin-bottom: 70px;}
  .agente-detalhe .banner-about .card {height: 220px;}
  .agente-detalhe .address {text-align: left;}
  .agente-detalhe .address h5 {margin-bottom: 20px;}
  .agente-detalhe .address .name {padding-left: 0;}
  .agente-detalhe .address p {font-size: 15px;}
  .about a {float: none;}
    /* FIM DETALHE AGENTES */

    /* CONTATO */
  .contato {font-size: 40px 0;}
  .contato h2 {font-size: 25px; text-align: left; line-height: 30px;}
  .contato hr {width: 40%; margin-bottom: 30px;}
  .contato button {height: 50px; font-size: 18px; width: 100px;}
    /* FIM CONTATO */

    /* CTA */
  .cta {height: auto; background-size: cover; text-align: center; background-position: center center;}
  .cta h3 {font-size: 18px; margin-top: 50px;}
  .cta h4 {font-size: 25px; line-height: 30px;}
  .cta .row .col:last-child {text-align: center;}
  .cta .btn-blue {margin-top: 20px; margin-bottom: 50px;}
    /* FIM CTA */

    /* FOOTER */
  footer {padding: 40px 0 0 0; background-attachment: inherit;}
  footer .flags img {width: 90px; height: auto; margin: 0;}
  footer .columns { margin-top: 30px;}
  footer .logo-footer {text-align: center;}
  footer .columns .logo-footer svg {width: 65%; margin-bottom: 20px;}
  footer .columns .col {min-height: 1px; border: 0;}
  footer .columns ul {padding-left: 0; text-align: center; margin: 20px 0 0 0; border-bottom: 1px solid rgba(255,255,255,0.3); padding-bottom: 20px;}
  footer .columns p {padding-left: 0; padding-top: 10px; font-size: 15px; margin-bottom: 10px;}
  footer .columns p:first-child {margin-top: 20px;}
  footer .columns .social {padding-left: 0; text-align: center; margin-top: 40px;}
  footer .columns .grid_3 {display: none;}
  footer .copyright {margin-top: 40px;}
  footer .copyright span {padding-bottom: 25px; }
  /* FIM FOOTER */
}
@media (max-width: 320px) {}
`;
