import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAppContext } from '../../providers/ApplicationProvider';
import api from '../../services/api';
import { WEB_FAQ_CATEGORY } from '../../utils/config';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AgentesFooter from '../../components/Agentes/Footer';

function Duvidas() {
  const { _token } = useAppContext();
  const [questions, setQuestions] = useState(null);

  const loadQuestions = async () => {
    const config = {
      headers: { Authorization: `Bearer ${_token}` },
    };

    api
      .get(`faqs?category_id=${WEB_FAQ_CATEGORY}`, config)
      .then(response => {
        const { data } = response.data;
        setQuestions(data);
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  return (
    <>
      <Header />

      <section className="banner-interna bg02">
        <div className="container">
          <h1>
            Dúvidas frequentes
            <span className="breadcrumb">
              <a href="/">home</a> / Dúvidas frequentes
            </span>
          </h1>
        </div>
      </section>

      <section className="duvidas">
        <div className="container">
          <h3>
            Recebemos muitas dúvidas de agentes de futebol, atletas, familiares
            de atletas e interessados no mercado.
          </h3>
          <h4>
            Confira abaixo as principais dúvidas sobre o mercado de Agentes de
            Futebol
          </h4>
          {questions && (
            <div className="faq grid_8">
              <ul>
                {questions.map(question => {
                  return (
                    <li key={question.id}>
                      <label htmlFor={`ask${question.id}`}>
                        <input
                          name="ask"
                          id={`ask${question.id}`}
                          type="checkbox"
                        />
                        <h6>{question.title}</h6>
                        <div className="question">
                          <p>{question.content}</p>
                        </div>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <div className="contact-duvidas">
            <h5>Não encontrou sua dúvida?</h5>
            <Link to="/contato">Entre em contato conosco</Link>
          </div>
        </div>
      </section>

      <AgentesFooter />
      <Footer />
    </>
  );
}

export default Duvidas;
