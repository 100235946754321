import React from 'react';

import MainRoutes from './routes/MainRoutes';

import {
  GlobalStyle,
  ResponsiveStyle,
  SlickStyle,
  SlickThemeStyle,
} from './styles/styles';

function App() {
  return (
    <>
      <ResponsiveStyle />
      <SlickStyle />
      <SlickThemeStyle />
      <GlobalStyle />
      <MainRoutes />
    </>
  );
}

export default App;
