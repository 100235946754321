import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import api from '../../services/api';
import { REACT_APP_API_PASSWORD, REACT_APP_API_USER } from '../../utils/config';

const ApplicationContext = createContext();

function ApplicationProvider({ children }) {
  const [_token, setToken] = useState(() => {
    return sessionStorage.getItem('token') || null;
  });

  const saveItemToStorage = useCallback((key, value) => {
    sessionStorage.setItem(key, value);
  }, []);

  const getExpirationDate = useCallback(token => {
    if (!token) {
      return null;
    }

    const jwt = JSON.parse(atob(token.split('.')[1]));

    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  }, []);

  const isExpired = useCallback(exp => {
    if (!exp) {
      return false;
    }
    return Date.now() > exp;
  }, []);

  const authenticate = useCallback(async () => {
    const email = REACT_APP_API_USER;
    const password = REACT_APP_API_PASSWORD;

    await api.post('sessions/auth', { email, password }).then(response => {
      const { token } = response.data;

      saveItemToStorage('token', token);
      setToken(token);
    });
  }, []);

  const isLoggedIn = useCallback(() => {
    const expired = isExpired(getExpirationDate(_token));
    return !!sessionStorage.getItem('token') || expired;
  }, []);

  const sendContactMessage = useCallback(
    async body => {
      const config = {
        method: 'post',
        url: '/faqs/contact/send',
        headers: { Authorization: `Bearer ${_token}` },
        data: body,
      };

      api.request(config).catch(err => {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
    },
    [_token],
  );

  useEffect(() => {
    if (!isLoggedIn()) {
      authenticate();
    }
  }, []);

  return (
    <ApplicationContext.Provider
      value={{
        _token,
        sendContactMessage,
        authenticate,
        isLoggedIn,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}

export const useAppContext = () => {
  const context = useContext(ApplicationContext);

  if (!context) {
    throw new Error(
      'You must surround your components with a ApplicationProvider before call useAppContext',
    );
  }

  return context;
};

export default ApplicationProvider;
