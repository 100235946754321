import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@unform/web';

import { useAppContext } from '../../providers/ApplicationProvider';
import api from '../../services/api';
import { REACT_APP_AWS_BUCKET_PATH } from '../../utils/config';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AgentesFooter from '../../components/Agentes/Footer';
// import Input from '../../components/Input';

import logoAgenteImg from '../../assets/images/generic_user.jpeg';

function AgentImage({ agent }) {
  return (
    <img
      src={
        agent.profile_image
          ? `${REACT_APP_AWS_BUCKET_PATH}/uploads/agents/images/${agent.profile_image}`
          : logoAgenteImg
      }
      width="110"
      height="110"
      alt={agent.name}
    />
  );
}

function Agentes() {
  const { _token } = useAppContext();
  const [agents, setAgents] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  // const searchFormRef = useRef(null);

  const loadAgents = async (_page = 1) => {
    const config = {
      headers: { Authorization: `Bearer ${_token}` },
    };

    api
      .get(`agents?status=1&page=${_page}`, config)
      .then(response => {
        const { data, last_page } = response.data;

        setAgents(data);
        setPages(last_page);
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      });
  };

  // const searchAgents = () => {};

  const handlePagination = (e, _page) => {
    setPage(_page);
    loadAgents(_page);
    e.preventDefault();
  };

  useEffect(() => {
    loadAgents(page);
  }, []);

  return (
    <>
      <Header />

      <section className="banner-interna bg03">
        <div className="container">
          <h1>
            Agentes ABAF
            <span className="breadcrumb">
              <Link to="/">home</Link> / AGENTES CREDENCIADOS
            </span>
          </h1>
        </div>
      </section>

      <section className="agentes">
        <div className="container">
          {/* <div className="search">
            <Form
              ref={searchFormRef}
              onSubmit={data => searchAgents(data)}
              className="grid_7"
            >
              <Input
                type="text"
                name="search"
                placeholder="Faça aqui a sua pesquisa por nome, estado…"
              />
              <button type="submit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42.313"
                  height="42.313"
                  viewBox="0 0 42.313 42.313"
                >
                  <path
                    id="XMLID_223_"
                    d="M45.28,40.294l-8.951-8.951a17.664,17.664,0,1,0-4.986,4.986l8.951,8.951a3.526,3.526,0,0,0,4.986-4.986ZM9.289,21.63A12.341,12.341,0,1,1,21.63,33.972,12.355,12.355,0,0,1,9.289,21.63Z"
                    transform="translate(-4 -4)"
                    fill="#005b88"
                  />
                </svg>
              </button>
            </Form>
          </div> */}

          <div className="pagination">
            {page > 1 && (
              <a
                href="/agents"
                onClick={e => handlePagination(e, page - 1)}
                className="prev"
              >
                &nbsp;
              </a>
            )}
            {pages > 1 &&
              Array.from(Array(pages).keys()).map((value, index) => (
                <a
                  key={index.toString()}
                  href="/agents"
                  onClick={e => handlePagination(e, value + 1)}
                  className={page === value + 1 ? 'active' : ''}
                >
                  {value + 1}
                </a>
              ))}
            {page < pages && (
              <a
                href="/agents"
                onClick={e => handlePagination(e, page + 1)}
                className="next"
              >
                &nbsp;
              </a>
            )}
          </div>

          <ul className="agent-list row gutters">
            {agents.map(agent => {
              return (
                <li key={agent.id} className="col grid_6">
                  <a
                    href={`https://monocard.com.br/u/${agent.monocard_user}`}
                    target="_blank"
                    rel="noreferrer"
                    className="row gutters"
                  >
                    <div className="image col grid_2">
                      <i>
                        <AgentImage agent={agent} />
                      </i>
                    </div>
                    <div className="text col grid_7">
                      <h5>{agent.name}</h5>
                      <p>{agent.city}</p>
                      <p>E-mail: {agent.email}</p>
                      <p>Site: {agent.website}</p>
                    </div>
                    <div className="action col grid_3">
                      <div className="name" />
                      <i className="button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="43"
                          viewBox="0 0 16 43"
                        >
                          <text
                            id="_"
                            data-name="+"
                            transform="translate(0 31)"
                            fill="#00386e"
                            fontSize="30"
                            fontFamily="SourceSansPro-Bold, Source Sans Pro"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              +
                            </tspan>
                          </text>
                        </svg>
                      </i>
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      <AgentesFooter />
      <Footer />
    </>
  );
}

export default Agentes;
