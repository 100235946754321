import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Agentes from '../pages/Agentes';
import AgenteDetalhe from '../pages/AgenteDetalhe';
import Sobre from '../pages/Sobre';
import Duvidas from '../pages/Duvidas';
import Contato from '../pages/Contato';
import NotFound from '../pages/NotFound';

import ApplicationProvider from '../providers/ApplicationProvider';

function MainRoutes() {
  return (
    <Router>
      <ApplicationProvider>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/agentes" exact component={Agentes} />
          <Route path="/agentes/:slug" exact component={AgenteDetalhe} />
          <Route path="/abaf" exact component={Sobre} />
          <Route path="/duvidas" exact component={Duvidas} />
          <Route path="/contato" exact component={Contato} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ApplicationProvider>
    </Router>
  );
}

export default MainRoutes;
