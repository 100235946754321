import React from 'react';

import mobileCardImg from '../../../assets/images/mobile-card.jpg';

function ServicesList() {
  return (
    <section className="list">
      <div className="container">
        <h3 className="grid_9">
          RECONHECIMENTO, GARANTIA E SEGURANÇA JURÍDICA NAS SUAS OPERAÇÕES
        </h3>
        <h4>Credibilidade e segurança para seus atletas</h4>
        <hr className="hr-blue" />
        <ul className="grid_5">
          <li>
            <span>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42.1"
                  height="31.049"
                  viewBox="0 0 42.1 31.049"
                >
                  <g id="identification" transform="translate(0 5.25)">
                    <path
                      id="Path_2912"
                      data-name="Path 2912"
                      d="M119.16,90.819a4.535,4.535,0,1,0-4.535-4.535A4.535,4.535,0,0,0,119.16,90.819Zm0-7.306a2.78,2.78,0,1,1-2.78,2.78A2.78,2.78,0,0,1,119.16,83.513Zm0,0"
                      transform="translate(-106.582 -80.895)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2913"
                      data-name="Path 2913"
                      d="M86.964,234.873a6.805,6.805,0,0,0-4.929,2.061A7.275,7.275,0,0,0,80,242.082a.88.88,0,0,0,.877.877H93.051a.88.88,0,0,0,.877-.877,7.275,7.275,0,0,0-2.035-5.148A6.8,6.8,0,0,0,86.964,234.873Zm-5.149,6.333a5.366,5.366,0,0,1,1.474-3.044,5.167,5.167,0,0,1,7.35,0,5.391,5.391,0,0,1,1.474,3.044Zm0,0"
                      transform="translate(-74.387 -223.274)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2914"
                      data-name="Path 2914"
                      d="M37.715-5.25H4.385A4.387,4.387,0,0,0,0-.865V21.413A4.387,4.387,0,0,0,4.385,25.8H37.715A4.387,4.387,0,0,0,42.1,21.413V-.865A4.387,4.387,0,0,0,37.715-5.25Zm2.631,26.663a2.639,2.639,0,0,1-2.631,2.631H4.385a2.639,2.639,0,0,1-2.631-2.631V-.865A2.639,2.639,0,0,1,4.385-3.5H37.715A2.639,2.639,0,0,1,40.346-.865Zm0,0"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2915"
                      data-name="Path 2915"
                      d="M359.72,203.5H349.5a.877.877,0,1,0,0,1.754H359.72a.877.877,0,1,0,0-1.754Zm0,0"
                      transform="translate(-324.163 -194.103)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2916"
                      data-name="Path 2916"
                      d="M359.72,283.5H349.5a.877.877,0,1,0,0,1.754H359.72a.877.877,0,1,0,0-1.754Zm0,0"
                      transform="translate(-324.163 -268.489)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2917"
                      data-name="Path 2917"
                      d="M359.72,123.5H349.5a.877.877,0,1,0,0,1.754H359.72a.877.877,0,1,0,0-1.754Zm0,0"
                      transform="translate(-324.163 -119.716)"
                      fill="#005b88"
                    />
                  </g>
                </svg>
              </i>
            </span>
            <div>
              <h5>Carteira Agente Oficial</h5>
              <p>
                Identificação com carteira física e digital, contento QR Code
                com link para detalhes e dados de contato.
              </p>
            </div>
          </li>
          <li>
            <span>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.26"
                  height="38.26"
                  viewBox="0 0 38.26 38.26"
                >
                  <g id="advisor" transform="translate(-2 -2)">
                    <path
                      id="Path_2893"
                      data-name="Path 2893"
                      d="M38.217,20.686l-1.868-.625a.619.619,0,0,0-.4.006l-.2-1.231a4.478,4.478,0,0,0,2.264-3.271H38.7a1.279,1.279,0,0,0,1.275-1.275v-.638a1.275,1.275,0,0,0-1.033-1.25l.848-.848a.659.659,0,0,0,.185-.453v-.638A4.469,4.469,0,0,0,35.507,6H31.681a4.469,4.469,0,0,0-4.464,4.464V11.1a.659.659,0,0,0,.185.453l.848.848a1.275,1.275,0,0,0-1.033,1.25v.638a1.279,1.279,0,0,0,1.275,1.275h.689a4.478,4.478,0,0,0,2.264,3.271l-.2,1.231a.619.619,0,0,0-.4-.006l-1.868.625a5.739,5.739,0,0,0-4.942,5.72v.1l-3.852-4.814a3.182,3.182,0,0,0-3.163-2.933H10.638a.638.638,0,0,0-.638.638,3.188,3.188,0,0,0,3.188,3.188h2.206l8.634,12.945V39.8a1.919,1.919,0,0,0,1.913,1.913H42.521a.638.638,0,0,0,.638-.638V26.405a5.739,5.739,0,0,0-4.942-5.72Zm.478-6.4h-.638v-.638H38.7Zm-9.565,0h-.638v-.638h.638Zm0-3.188v.376l-.638-.638v-.376a3.188,3.188,0,0,1,3.188-3.188h3.826A3.188,3.188,0,0,1,38.7,10.464v.376l-.638.638V11.1a.623.623,0,0,0-.351-.568L35.156,9.258a.624.624,0,0,0-.44-.051l-5.1,1.275a.638.638,0,0,0-.485.619Zm1.275,3.826V11.6l4.387-1.1,1.99.995v3.431a3.188,3.188,0,0,1-6.377,0Zm4.419,6.16-1.231,1.231-1.231-1.231.3-1.8a4.3,4.3,0,0,0,1.862,0Zm-3.954.306,2.27,2.276a.643.643,0,0,0,.905,0l2.27-2.276.721.242L34.544,25.13h-1.9L30.15,21.636Zm3.883,7.563.708,4.247-1.868,1.868L31.725,33.2l.708-4.247Zm-2.111-1.275.217-1.275h1.467l.217,1.275ZM13.188,21.3a1.922,1.922,0,0,1-1.8-1.275h5.631a1.922,1.922,0,0,1,1.8,1.275Zm28.7,19.13H39.333V34.058h2.551Zm0-7.652H38.7a.638.638,0,0,0-.638.638v7.014H25.942A.638.638,0,0,1,25.3,39.8V35.333a.617.617,0,0,0-.108-.351l-8.264-12.4h2.327l4.91,6.134a.627.627,0,0,0,.708.2.638.638,0,0,0,.427-.6V26.405A4.473,4.473,0,0,1,28.843,22l2.806,3.922-1.237,7.4a.645.645,0,0,0,.179.555l2.551,2.551a.647.647,0,0,0,.905,0L36.6,33.873a.645.645,0,0,0,.179-.555l-1.237-7.4L38.344,22a4.473,4.473,0,0,1,3.539,4.406Z"
                      transform="translate(-2.899 -1.449)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2894"
                      data-name="Path 2894"
                      d="M3.913,16.029h15.3a1.913,1.913,0,0,0,1.913-1.913V3.913A1.913,1.913,0,0,0,19.217,2H3.913A1.913,1.913,0,0,0,2,3.913v10.2a1.913,1.913,0,0,0,1.913,1.913ZM3.275,3.913a.638.638,0,0,1,.638-.638h15.3a.638.638,0,0,1,.638.638v10.2a.638.638,0,0,1-.638.638H3.913a.638.638,0,0,1-.638-.638Z"
                      transform="translate(0)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2895"
                      data-name="Path 2895"
                      d="M6,6H7.275V7.275H6Z"
                      transform="translate(-1.449 -1.449)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2896"
                      data-name="Path 2896"
                      d="M10,6H21.478V7.275H10Z"
                      transform="translate(-2.899 -1.449)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2897"
                      data-name="Path 2897"
                      d="M6,14H20.029v1.275H6Z"
                      transform="translate(-1.449 -4.348)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2898"
                      data-name="Path 2898"
                      d="M6,10H20.029v1.275H6Z"
                      transform="translate(-1.449 -2.899)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2899"
                      data-name="Path 2899"
                      d="M6,18H20.029v1.275H6Z"
                      transform="translate(-1.449 -5.797)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2900"
                      data-name="Path 2900"
                      d="M9.551,49A2.551,2.551,0,1,0,12.1,51.551,2.551,2.551,0,0,0,9.551,49Zm0,3.826a1.275,1.275,0,1,1,1.275-1.275A1.275,1.275,0,0,1,9.551,52.826Z"
                      transform="translate(-1.812 -17.029)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2901"
                      data-name="Path 2901"
                      d="M13,47.845l-.427-.106.226-.377a.638.638,0,0,0-.1-.779l-1.8-1.8a.638.638,0,0,0-.779-.1l-.377.226-.106-.427A.638.638,0,0,0,9.014,44H6.464a.638.638,0,0,0-.619.483l-.106.427-.377-.226a.638.638,0,0,0-.779.1l-1.8,1.8a.638.638,0,0,0-.1.779l.226.377-.427.106A.638.638,0,0,0,2,48.464v2.551a.638.638,0,0,0,.483.619l.427.106-.226.377a.638.638,0,0,0,.1.779l1.8,1.8a.638.638,0,0,0,.779.1l.377-.226L5.845,55a.638.638,0,0,0,.619.483H9.014A.638.638,0,0,0,9.633,55l.106-.427.377.226a.638.638,0,0,0,.779-.1l1.8-1.8a.638.638,0,0,0,.1-.779l-.226-.377L13,51.633a.638.638,0,0,0,.483-.619V48.464A.638.638,0,0,0,13,47.845ZM12.2,50.516l-.779.191a.638.638,0,0,0-.392.947l.413.688-1.1,1.1-.688-.413a.638.638,0,0,0-.947.392l-.194.782H6.962l-.191-.779a.638.638,0,0,0-.947-.392l-.688.413-1.1-1.1.413-.688a.638.638,0,0,0-.392-.947l-.779-.194V48.962l.779-.191a.638.638,0,0,0,.392-.947l-.413-.688,1.1-1.1.688.413a.638.638,0,0,0,.947-.392l.191-.778H8.513l.191.778a.638.638,0,0,0,.947.392l.688-.413,1.1,1.1-.413.688a.638.638,0,0,0,.392.947l.779.191Z"
                      transform="translate(0 -15.218)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2902"
                      data-name="Path 2902"
                      d="M10,40h1.275v1.275H10Z"
                      transform="translate(-2.899 -13.769)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2903"
                      data-name="Path 2903"
                      d="M10,33h1.275v3.188H10Z"
                      transform="translate(-2.899 -11.232)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2904"
                      data-name="Path 2904"
                      d="M22,52h1.275v1.275H22Z"
                      transform="translate(-7.247 -18.116)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2905"
                      data-name="Path 2905"
                      d="M26,52h2.551v1.275H26Z"
                      transform="translate(-8.696 -18.116)"
                      fill="#005b88"
                    />
                  </g>
                </svg>
              </i>
            </span>
            <div>
              <h5>Assessoria Jurídica Especializada</h5>
              <p>
                Garanta a segurança das suas operações, tenha acesso a modelos
                de contratos e atendimento de dúvidas.
              </p>
            </div>
          </li>
          <li>
            <span>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.26"
                  height="37.112"
                  viewBox="0 0 36.26 37.112"
                >
                  <g id="case-study" transform="translate(-5.878)">
                    <path
                      id="Path_2906"
                      data-name="Path 2906"
                      d="M68,46.867h7.67a.543.543,0,1,0,0-1.087H68a1.173,1.173,0,0,0-1.171,1.171V48.93A1.173,1.173,0,0,0,68,50.1H85.514a1.173,1.173,0,0,0,1.171-1.171V46.951a1.173,1.173,0,0,0-1.171-1.171h-7.67a.543.543,0,0,0,0,1.087h7.67a.085.085,0,0,1,.084.085V48.93a.085.085,0,0,1-.084.085H68a.085.085,0,0,1-.084-.085V46.951A.085.085,0,0,1,68,46.867Z"
                      transform="translate(-56.535 -42.463)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2907"
                      data-name="Path 2907"
                      d="M99.952,130.427H83.524a.543.543,0,1,0,0,1.087H99.952a.543.543,0,0,0,0-1.087Z"
                      transform="translate(-71.516 -120.976)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2908"
                      data-name="Path 2908"
                      d="M99.952,415.373a.543.543,0,0,0,0-1.087H83.524a.543.543,0,1,0,0,1.087Z"
                      transform="translate(-71.516 -384.266)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2909"
                      data-name="Path 2909"
                      d="M211.9,209.677a5.684,5.684,0,1,0,0,8.039A5.691,5.691,0,0,0,211.9,209.677Zm-.769,7.27a4.6,4.6,0,0,1-7.446-1.368h6.842a.543.543,0,0,0,0-1.087h-7.175a4.608,4.608,0,0,1,.273-2.543h6.9a.543.543,0,1,0,0-1.087h-6.266a4.67,4.67,0,0,1,.368-.417,4.6,4.6,0,0,1,6.5,0h0a4.594,4.594,0,0,1,0,6.5Z"
                      transform="translate(-182.091 -192.942)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2910"
                      data-name="Path 2910"
                      d="M440.786,445.78a.543.543,0,0,0-.768.769l.777.777a.543.543,0,1,0,.768-.769Z"
                      transform="translate(-402.534 -413.331)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2911"
                      data-name="Path 2911"
                      d="M40.923,30.011l-.2-.2a.543.543,0,0,0-.769.769l.2.2a3.069,3.069,0,1,1-4.34,4.34l-3.571-3.571a.9.9,0,0,1,0-1.28l2.634-2.634h0l.425-.425a.906.906,0,0,1,1.28,0l1.811,1.811a.543.543,0,1,0,.769-.769l-1.811-1.811a1.994,1.994,0,0,0-2.817,0l-.041.041-1.736-1.736a.182.182,0,0,1-.026-.218,7.953,7.953,0,0,0,.507-6.414V1.28A1.281,1.281,0,0,0,31.96,0H7.158a1.281,1.281,0,0,0-1.28,1.28V30.168a.543.543,0,1,0,1.087,0V1.28a.193.193,0,0,1,.193-.193h24.8a.193.193,0,0,1,.193.193V16.07a8.014,8.014,0,0,0-.777-.9,7.906,7.906,0,0,0-8.876-1.6H12.008a.543.543,0,0,0,0,1.087h8.761a8.061,8.061,0,0,0-.652.6,7.84,7.84,0,0,0-1.605,2.425h-6.5a.543.543,0,0,0,0,1.087h6.137a8.02,8.02,0,0,0-.183,3.027H12.008a.543.543,0,0,0,0,1.087h6.173A7.817,7.817,0,0,0,19.8,25.906H12.008a.543.543,0,0,0,0,1.087h8.934a7.936,7.936,0,0,0,8.621.7.181.181,0,0,1,.216.027l1.736,1.736a2.008,2.008,0,0,0-.624,1.45,1.979,1.979,0,0,0,.583,1.408l.678.678v2.837a.193.193,0,0,1-.193.193H7.158a.193.193,0,0,1-.193-.193V32.341a.543.543,0,1,0-1.087,0v3.492a1.281,1.281,0,0,0,1.28,1.28h24.8a1.281,1.281,0,0,0,1.28-1.28v-1.75l1.806,1.806a4.156,4.156,0,0,0,5.877-5.878ZM30.549,26.955a1.26,1.26,0,0,0-1.5-.213,6.815,6.815,0,1,1,2.733-2.734,1.261,1.261,0,0,0,.212,1.506l1.736,1.736-1.441,1.441Z"
                      fill="#005b88"
                    />
                  </g>
                </svg>
              </i>
            </span>
            <div>
              <h5>Casos CNRD</h5>
              <p>
                Contratação de escritório especializado com condições especiais,
                Casos CNRD sem custos iniciais, remuneração sobre resultados.
              </p>
            </div>
          </li>
          <li>
            <span>
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="47.242"
                  height="32.259"
                  viewBox="0 0 47.242 32.259"
                >
                  <g
                    id="plane-ticket"
                    transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 11.142)"
                  >
                    <path
                      id="Path_2923"
                      data-name="Path 2923"
                      d="M71.408,197.772a.841.841,0,0,0,.841-.841v-7.219l2.276-2.276a.841.841,0,0,0-1.189-1.189l-2.276,2.276H63.841a.841.841,0,1,0,0,1.682h5.538l-3.363,3.363H63.841a.841.841,0,0,0,0,1.682h1.682v1.682a.841.841,0,0,0,1.682,0v-2.174l3.363-3.363v5.538A.841.841,0,0,0,71.408,197.772Z"
                      transform="translate(-57.703 -180.955)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2924"
                      data-name="Path 2924"
                      d="M246.841,187.682h7.568a.841.841,0,0,0,0-1.682h-7.568a.841.841,0,1,0,0,1.682Z"
                      transform="translate(-225.315 -180.955)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2925"
                      data-name="Path 2925"
                      d="M255.249,306.841a.841.841,0,0,0-.841-.841h-7.568a.841.841,0,1,0,0,1.682h7.568A.841.841,0,0,0,255.249,306.841Z"
                      transform="translate(-225.315 -290.865)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2926"
                      data-name="Path 2926"
                      d="M246.841,247.682h7.568a.841.841,0,0,0,0-1.682h-7.568a.841.841,0,1,0,0,1.682Z"
                      transform="translate(-225.315 -235.91)"
                      fill="#005b88"
                    />
                    <ellipse
                      id="Ellipse_29"
                      data-name="Ellipse 29"
                      cx="1.5"
                      cy="0.5"
                      rx="1.5"
                      ry="0.5"
                      transform="translate(20.01 20.924)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2927"
                      data-name="Path 2927"
                      d="M.841,147.862h16.9a.841.841,0,0,0,0-1.682H1.682v-18.5H33.3v1.682a.841.841,0,1,0,1.682,0v-1.682h6.39v5.992a3.364,3.364,0,0,0,0,6.514v5.992h-6.39V144.5a.841.841,0,0,0-1.682,0v1.682H25.309a.841.841,0,0,0,0,1.682h16.9a.841.841,0,0,0,.841-.841v-7.568a.841.841,0,0,0-.841-.841,1.682,1.682,0,0,1,0-3.363.841.841,0,0,0,.841-.841v-7.568A.841.841,0,0,0,42.21,126H.841a.841.841,0,0,0-.841.841v20.18A.841.841,0,0,0,.841,147.862Z"
                      transform="translate(0 -126)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2928"
                      data-name="Path 2928"
                      d="M396.841,222.727a.841.841,0,0,0,.841-.841v-5.045a.841.841,0,0,0-1.682,0v5.045A.841.841,0,0,0,396.841,222.727Z"
                      transform="translate(-362.703 -208.432)"
                      fill="#005b88"
                    />
                  </g>
                </svg>
              </i>
            </span>
            <div>
              <h5>Passagens e Hotéis</h5>
              <p>
                Sistema online para comprade passagens e reserva de hotéis com
                preços e atendimento diferenciado.
              </p>
            </div>
          </li>
          <li>
            <span>
              <i>
                <svg
                  id="online-course"
                  xmlns="http://www.w3.org/2000/svg"
                  width="42.043"
                  height="41.058"
                  viewBox="0 0 42.043 41.058"
                >
                  <g
                    id="Group_3133"
                    data-name="Group 3133"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_2918"
                      data-name="Path 2918"
                      d="M266.823,128a.821.821,0,0,0,0-1.642h0a.821.821,0,0,0,0,1.642Z"
                      transform="translate(-244.158 -116.47)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2919"
                      data-name="Path 2919"
                      d="M226.822,128a.821.821,0,1,0,0-1.642h0a.821.821,0,1,0,0,1.642Z"
                      transform="translate(-207.442 -116.47)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2920"
                      data-name="Path 2920"
                      d="M39.58,42.132H33a2.458,2.458,0,0,0-.488-2.463,2.453,2.453,0,0,0,.629-1.642V27.206a2.467,2.467,0,0,0-1.684-2.336,11.784,11.784,0,0,0-4.593-4.917A6.962,6.962,0,0,0,28.025,16.1V14.629c.115-.04.231-.083.348-.134a.821.821,0,0,0-.35-1.573,7,7,0,0,0-13.992-.34,1.066,1.066,0,0,1-.361-.165.821.821,0,0,0-1.059,1.255,3.185,3.185,0,0,0,1.406.595V16.1a6.962,6.962,0,0,0,1.161,3.856,11.781,11.781,0,0,0-4.592,4.916A2.467,2.467,0,0,0,8.9,27.206v10.82a2.453,2.453,0,0,0,.629,1.642,2.458,2.458,0,0,0-.488,2.463H2.463a2.463,2.463,0,0,0,0,4.927H39.58a2.463,2.463,0,0,0,0-4.927ZM21.021,7.643a5.368,5.368,0,0,1,5.362,5.362V13.2A5.873,5.873,0,0,1,23.1,11.213a.821.821,0,0,0-.629-.3h-.008a.821.821,0,0,0-.627.291c-.547.648-2.244,1.243-4.223,1.48a13.972,13.972,0,0,1-1.946.1,5.368,5.368,0,0,1,5.356-5.141Zm-3.215,6.668a11.311,11.311,0,0,0,4.6-1.444,6.7,6.7,0,0,0,3.977,1.986V16.1a5.362,5.362,0,0,1-10.723,0v-1.67A16.575,16.575,0,0,0,17.806,14.311ZM16.26,21.226a6.987,6.987,0,0,0,9.524,0,10.14,10.14,0,0,1,3.725,3.516H12.535a10.139,10.139,0,0,1,3.725-3.516ZM10.543,41.31a.822.822,0,0,1,.821-.821h5.961a.821.821,0,0,0,0-1.642H11.364a.822.822,0,0,1-.821-.821V27.206a.822.822,0,0,1,.821-.821H30.677a.822.822,0,0,1,.821.821v10.82a.822.822,0,0,1-.821.821H24.716a.821.821,0,0,0,0,1.642h5.962a.821.821,0,1,1,0,1.642H11.364A.822.822,0,0,1,10.543,41.31ZM39.58,45.416H2.463a.821.821,0,0,1,0-1.642H39.58a.821.821,0,1,1,0,1.642Z"
                      transform="translate(0 -6.001)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2921"
                      data-name="Path 2921"
                      d="M223.866,295.12a2.874,2.874,0,1,0,2.874,2.874A2.877,2.877,0,0,0,223.866,295.12Zm0,4.106a1.232,1.232,0,1,1,1.232-1.232A1.233,1.233,0,0,1,223.866,299.226Z"
                      transform="translate(-202.845 -271.379)"
                      fill="#005b88"
                    />
                    <path
                      id="Path_2922"
                      data-name="Path 2922"
                      d="M246.809,406h0a.821.821,0,1,0,.822.821A.821.821,0,0,0,246.809,406Z"
                      transform="translate(-225.789 -373.155)"
                      fill="#005b88"
                    />
                  </g>
                </svg>
              </i>
            </span>
            <div>
              <h5>Cursos e Eventos</h5>
              <p>
                Acesso a cursos e eventos online e presenciais, conteúdos de
                alta qualidade para estar sempre atualizado para as novidades e
                desafios do mercado.
              </p>
            </div>
          </li>
        </ul>
        <a
          href="http://irion.kpages.online/abaf_assinatura"
          className="btn-larger btn-yellow"
        >
          SEJA UM AGENTE ASSOCIADO
        </a>
      </div>
      <img
        src={mobileCardImg}
        width="840"
        height="758"
        alt="Mobile"
        className="mobile-card"
      />
    </section>
  );
}

export default ServicesList;
