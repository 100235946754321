import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

function NotFound() {
  return (
    <>
      <Header />

      <section className="contato">
        <div className="container">
          <h2>Página não encontrada</h2>
          <hr className="hr-yellow" />
        </div>
      </section>

      <Footer />
    </>
  );
}

export default NotFound;
