import React from 'react';
import Slider from 'react-slick';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AgentesFooter from '../../components/Agentes/Footer';

import bannerImg01 from '../../assets/images/abaf_sessao_01.png';
import bannerImg02 from '../../assets/images/abaf_sessao_02.png';
import bannerImg03 from '../../assets/images/abaf_sessao_03.png';

import imgDiretoria01 from '../../assets/images/jorge-moraes.png';
import imgDiretoria02 from '../../assets/images/falvio-pires.png';
import imgDiretoria03 from '../../assets/images/anselmo-paiva.png';
import imgDiretoria04 from '../../assets/images/reinaldo-pitta.png';
import imgDiretoria06 from '../../assets/images/foto_claudio.png';

function Sobre() {
  const options = {
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 5,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Header />

      <section className="banner-interna bg01">
        <div className="container">
          <h1>
            Conheça a ABAF
            <span className="breadcrumb">
              <a href="/">home</a> / ABAF
            </span>
          </h1>
        </div>
      </section>

      <section className="abaf">
        <div className="container">
          <div className="row gutters">
            <div className="col grid_7 text">
              <h2>ABAF - Associação Brasileira De Agentes de Futebol</h2>
              <h3>
                Mais de 30 anos representando e promovendo os Agentes de
                Jogadores
              </h3>
              <p>
                Criada em 1990 por xxx, existe para defender, agregar, moralizar
                e valorizar a profissão de Agente de Futebol.
              </p>
              <p>
                <strong>Confira abaixo os objetivos da ABAF:</strong>
              </p>
              <p>
                <strong>1.</strong> Congregar os Agentes de jogadores,
                credenciados pela CBF, com o objetivo de moralizar e dignificar
                a profissão de agentes, fiscalizando o cumprimento da legislação
                e defender seus interesses, quer no âmbito nacional, quer no
                internacional;
              </p>
              <p>
                <strong>2.</strong> Promover a união dos associados e a defesa
                de seus direitos e prerrogativas legítimos;
              </p>
              <p>
                <strong>3.</strong> Representar os associados em juízo ou fora
                dele, em qualquer instância ou tribunal;
              </p>
              <p>
                <strong>4.</strong> Fazer os associados respeitarem a disciplina
                e a ética profissional, assegurando-lhes o prestígio e a
                dignidade da função;
              </p>
              <p>
                <strong>5.</strong> Promover o aperfeiçoamento da legislação,
                concernente ao trabalho dos AGENTES DE JOGADORES, auxiliando,
                direta ou indiretamente, os poderes competentes na redação de
                textos pertinentes;
              </p>
              <p>
                <strong>6.</strong> Prestar assistência a seus associados,
                oferecendo-lhes incentivos no trato com entidades esportivas
                ligadas ao futebol, seja nacional, seja internacional;
              </p>
              <p>
                <strong>7.</strong> Fiscalizar o cumprimento da legislação
                nacional ou internacional quanto à profissão de AGENTES DE
                JOGADORES;
              </p>
              <p>
                <strong>8.</strong> Fiscalizar o cumprimento das determinações
                emanadas pela FIFA e da CBF dando a esta, ou à entidade nacional
                ligada ao futebol, dos seus infratores quando estranhos aos
                quadros da ABAF e, punindo-os indiretamente quando aos seus
                quadros pertencerem.
              </p>
              <p>
                <strong>9.</strong> Realizar cursos profissionalizantes,
                congressos, simpósios, seminários, encontros, conferências,
                palestras, debates e exposição sobre assuntos relativos aos
                interesses da classe e do futebol em geral, assim como
                participar desses eventos promovidos por outras entidades;
              </p>
              <p>
                <strong>10.</strong> Auxiliar os candidatos a AGENTES DE
                JOGADORES no seu credenciamento, encaminhando-os à entidade
                nacional (CBF);
              </p>
              <p>
                <strong>11.</strong> Incentivar a inscrição na CBF de
                profissionais do ramo que não estejam legalizados como AGENTES
                DE JOGADORES;
              </p>
              <p>
                <strong>12.</strong> Filiar-se, se houver possibilidade, ou for
                do interesse da classe, à FIFA, à IAFA, ou à outra entidade de
                âmbito nacional ou internacional, assim como promover a filiação
                dos seus associados à IAFA, se, também, for de seus interesses;
              </p>
              <p>
                <strong>13.</strong> Ajudar no registro dos os contratos dos
                jogadores de futebol profissionais junto à entidade máxima do
                futebol, representados por seus agentes.
              </p>
              <p>
                <strong>14.</strong> Ajudar também, no registro de atletas de
                amadores, representados por seus agentes.
              </p>
            </div>
            <div className="col grid_5">
              <p className="image">
                <img src={bannerImg01} alt="Banner 01" />
              </p>
              <p className="image">
                <img src={bannerImg02} alt="Banner 02" />
              </p>
              <p className="image">
                <img src={bannerImg03} alt="Banner 03" />
              </p>
            </div>
          </div>
        </div>
        <div className="diretoria">
          <div className="container">
            <h2>diretoria abaf</h2>
            <Slider {...options} className="banner-diretoria">
              <div className="card">
                <div className="foto">
                  <img src={imgDiretoria01} alt="Jorge Moraes" />
                </div>
                <h6>
                  Jorge Moraes <span>presidente</span>
                </h6>
              </div>
              <div className="card">
                <div className="foto">
                  <img src={imgDiretoria02} alt="Flavio Pires" />
                </div>
                <h6>
                  Flavio Pires <span>vice-presidente</span>
                </h6>
              </div>
              <div className="card">
                <div className="foto">
                  <img src={imgDiretoria03} alt="Anselmo Paiva" />
                </div>
                <h6>
                  Anselmo Paiva <span>secretário geral</span>
                </h6>
              </div>
              <div className="card">
                <div className="foto">
                  <img src={imgDiretoria04} alt="Reinaldo Pitta" />
                </div>
                <h6>
                  Reinaldo Pitta <span>diretor financeiro</span>
                </h6>
              </div>
              <div className="card">
                <div className="foto">
                  <img src={imgDiretoria06} alt="Cláudio Guadagno" />
                </div>
                <h6>
                  Cláudio Guadagno <span>diretor de marketing</span>
                </h6>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <AgentesFooter />
      <Footer />
    </>
  );
}

export default Sobre;
