import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

function AboutBanner({ images }) {
  const slickRef = useRef(null);
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  useEffect(() => {
    slickRef.current.slickNext();
    slickRef.current.slickPlay();
  }, []);

  return (
    <Slider ref={slickRef} {...options} className="col grid_5 banner-about">
      {images.map(image => (
        <div className="card">
          <img src={image.filename} alt={image.description} />
        </div>
      ))}
    </Slider>
  );
}

export default AboutBanner;
