import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import bannerImg01 from '../../../assets/images/abaf_01.png';
import bannerImg02 from '../../../assets/images/abaf_02.png';
import bannerImg03 from '../../../assets/images/abaf_03.png';

function About() {
  const slickRef = useRef(null);
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  useEffect(() => {
    slickRef.current.slickNext();
    slickRef.current.slickPlay();
  }, []);

  return (
    <section className="about">
      <div className="container">
        <div className="row gutters">
          <Slider
            ref={slickRef}
            {...options}
            className="col grid_6 banner-about slick-dotted"
          >
            <div className="card">
              <img src={bannerImg01} alt="Banner Sobre" />
            </div>
            <div className="card">
              <img src={bannerImg02} alt="Banner 01" />
            </div>
            <div className="card">
              <img src={bannerImg03} alt="Banner 02" />
            </div>
          </Slider>
          <div className="col grid_5">
            <h3>ABAF - Associação Brasileira De Agentes de Futebol</h3>
            <h4>
              Mais de 30 anos representando e promovendo os Agentes de Jogadores
            </h4>
            <p>
              Criada em 1990, existe para defender, agregar, moralizar e
              valorizar a profissão de Agente de Futebol.
            </p>
            <p>
              A ABAF congrega os Agentes de jogadores, com o objetivo de
              moralizar e dignificar a profissão de agentes, fiscalizando o
              cumprimento da legislação e defendendo seus interesses, quer no
              âmbito nacional, quer no internacional.
            </p>
            <br />
            <Link to="/abaf" className="btn-small btn-blue">
              saiba mais
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
