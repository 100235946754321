import React, { useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import * as yup from 'yup';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AgentesFooter from '../../components/Agentes/Footer';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';

import { getValidationSchema } from '../../utils/schemas';

import { useAppContext } from '../../providers/ApplicationProvider';

function Contato() {
  const contactFormRef = useRef(null);
  const { sendContactMessage } = useAppContext();

  const handleFormSubmit = useCallback(async data => {
    try {
      // Remove all previous errors
      contactFormRef.current.setErrors({});

      const contactSchema = getValidationSchema('contact');
      const schema = yup.object().shape(contactSchema);

      await schema.validate(data, {
        abortEarly: false,
      });

      await sendContactMessage(data);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        contactFormRef.current.setErrors(validationErrors);
      }
    }
  }, []);

  return (
    <>
      <Header />

      <section className="banner-interna bg04">
        <div className="container">
          <h1>
            Contato
            <span className="breadcrumb">
              <a href="index.html">home</a> / contato
            </span>
          </h1>
        </div>
      </section>

      <section className="contato">
        <div className="container">
          <h2>Entre em contato conosco</h2>
          <Form
            ref={contactFormRef}
            onSubmit={data => handleFormSubmit(data)}
            className="row gutters grid_10"
          >
            <div className="col grid_6">
              <Input type="text" name="from_name" placeholder="Nome:" />
            </div>
            <div className="col grid_6">
              <Input type="text" name="subject" placeholder="Assunto:" />
            </div>
            <div className="grid_12">
              <TextArea name="content" placeholder="Mensagem:" />
            </div>
            <button type="submit">ENVIAR</button>
          </Form>
        </div>
      </section>

      <AgentesFooter />
      <Footer />
    </>
  );
}

export default Contato;
