import * as yup from 'yup';
import { parse, isValid, isAfter, isBefore, subYears } from 'date-fns';

yup.addMethod(yup.string, 'isValidCpf', message => {
  return yup.mixed().test({
    message,
    name: 'isValidCpf',
    exclusive: true,
    test(value = '') {
      const strCPF = value.replace(/\D/g, '').padStart(11, '0');

      let Soma;
      let Resto;

      Soma = 0;
      if (strCPF === '00000000000') {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
        return false;
      }

      Soma = 0;

      for (let i = 1; i <= 10; i++) {
        Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
      }

      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) {
        Resto = 0;
      }

      if (Resto !== parseInt(strCPF.substring(10, 11), 10)) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, 'isValidaDate', message => {
  return yup.mixed().test({
    message,
    name: 'isValidaDate',
    exclusive: true,
    test(value = '') {
      const data = parse(value, 'dd/MM/yyyy', new Date());
      const dataMinima = subYears(new Date(), 18);
      const dataMaxima = subYears(new Date(), 100);

      if (!isValid(data)) {
        return false;
      }

      if (isAfter(data, dataMinima) || isBefore(data, dataMaxima)) {
        return false;
      }

      return true;
    },
  });
});

yup.addMethod(yup.string, 'isTinyName', message => {
  return yup.mixed().test({
    message,
    name: 'isTinyName',
    exclusive: true,
    test(value = '') {
      const parsedValue = value.trim().split(' ');
      let isValidName = true;

      if (parsedValue.length <= 2) {
        parsedValue.forEach(currentValue => {
          if (currentValue.length < 3) {
            isValidName = false;
          }
        });
      }

      return parsedValue.length >= 2 && isValidName;
    },
  });
});

yup.addMethod(yup.string, 'isCellPhone', message => {
  return yup.mixed().test({
    message,
    name: 'isCellPhone',
    exclusive: true,
    test(value = '') {
      const parsedValue = value.trim().replace(/\D/g, '');

      // Verifying if it is a cellphone number
      if (parsedValue.length === 11) {
        const ddd = parsedValue.substring(0, 2) * 1;

        // Does not exists DDD smaller than 11 in Brazil
        if (ddd < 11) {
          return false;
        }

        const thisNumber = parsedValue.substring(2);
        const firstDigit = thisNumber.substring(1, 0) * 1;

        if (firstDigit !== 9) {
          // It is not a valid cellphone in Brazil
          return false;
        }

        return true;
      }

      return false;
    },
  });
});

export function getValidationSchema(step) {
  const schemas = {
    contact: {
      from_name: yup
        .string()
        .isTinyName('Nome inválido')
        .required('Insira seu nome completo'),
      subject: yup.string().required('Insira o assunto da mensagem'),
      content: yup.string().required('Escreva uma mensagem'),
    },
  };

  return schemas[step];
}
