import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

function Banner() {
  const slickRef = useRef(null);
  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    slickRef.current.slickNext();
    slickRef.current.slickPlay();
  }, []);

  return (
    <section className="banner">
      <Slider ref={slickRef} {...options} className="fade slick-dotted">
        <div className="card-banner banner01">
          <div className="container">
            <h1>
              Esteja entre os grandes, seja um
              <span>Agente Associado ABAF!</span>
            </h1>
            <h2>
              Reconhecimento, garantia e segurança
              <br />
              jurídica nas suas operações
            </h2>
            <a
              href="http://irion.kpages.online/abaf_assinatura"
              className="bt-banner btn-yellow btn-medium"
            >
              saiba mais
            </a>
          </div>
        </div>
      </Slider>
    </section>
  );
}

export default Banner;
