import React from 'react';

function Footer() {
  return (
    <section className="cta">
      <div className="container">
        <div className="row gutters">
          <div className="col grid_9">
            <h3>Seja um Agente Associado ABAF!</h3>
            <h4>Credibilidade e segurança para seus atletas</h4>
          </div>
          <div className="col grid_3">
            <a
              href="http://irion.kpages.online/abaf_assinatura"
              className="btn-blue btn-medium"
            >
              Saiba mais
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
